<script>
import AuthService from '@/service/auth.service';
import StudentResponsiblesService from '@/service/student-responsibles.service';

export default {
  name: 'list-student-responsibles',

  components: {
    ModalRegisterStudentResponsible: () => import('./ModalRegisterStudentResponsible.vue'),
  },

  props: {
    studentId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      options: {
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false],
        search: '',
      },

      totalItems: 0,
      items: [],

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Responsável', value: 'name' },
        { text: 'Parentesco', value: 'kinship', sortable: false },
        { text: 'Telefone', value: 'phone', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Ações', value: 'actions', align: 'right', sortable: false },
      ],
    };
  },

  watch: {
    options: {
      handler() {
        this.fetch();
      },

      deep: true,
    },

    studentId: {
      handler() {
        if (this.studentId) {
          this.fetch();
        }
      },
    },
  },

  methods: {
    async fetch() {
      this.loading = true;
      if (!this.studentId) {
        return false;
      }

      try {
        const filter = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
          search: this.options.search,
        };

        if (this.options.sortBy.length > 0) {
          filter.sortBy = `${this.options.sortBy[0]}:${this.options.sortDesc[0] ? 'DESC' : 'ASC'}`;
        }

        filter['filter.studentId'] = `$eq:${this.studentId}`;

        const { data, meta } = await StudentResponsiblesService.find(filter);
        this.items = data;
        this.page = meta?.currentPage;
        this.totalItems = meta?.totalItems;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    create() {
      // console.log('this.$refs.modalRegister', this.$refs.modalRegister);
      this.$refs.modalRegister.form.studentId = this.studentId;
      this.$refs.modalRegister.$emit('create');
    },

    edit(payload) {
      this.$refs.modalRegister.$emit('edit', payload.id);
    },

    remove(payload) {
      this.$swal({
        text: `Deseja remover '${payload.name}'?`,
        icon: 'warning',
        confirmButtonText: 'SIM',
        showDenyButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return StudentResponsiblesService.delete(payload.id);
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: 'success',
              title: 'Removido!',
              text: 'Responsável removido com sucesso!',
              timer: 2000,
              showConfirmButton: false,
            });
            this.fetch();
          }
        })
        .catch(() => {
          this.$swal.close();
        });
    },

    releaseResponsibleAccess(payload) {
      const _payload = {
        email: payload.email,
        cpf: payload.cpf,
        name: payload.name,
      };

      this.$swal({
        text: `Deseja liberar o responsável '${payload.name}' para acesso mobile?`,
        icon: 'warning',
        confirmButtonText: 'SIM',
        showDenyButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return AuthService.registerStudentResponsible(_payload);
        },
      })
        .then((result) => {
          console.log(result);
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: 'success',
              title: 'Liberado!',
              text: 'Responsável liberado com sucesso!',
              timer: 2000,
              showConfirmButton: false,
            });
            this.fetch();
          }
        })
        .catch(() => {
          this.$swal.close();
        });
    },
  },
};
</script>

<template>
  <div>
    <v-row class="mb-0" align="center">
      <v-col cols="2">
        <v-btn color="primary" width="100%" @click="create">Novo Responsável</v-btn>
      </v-col>
      <v-col cols="4" offset="6">
        <v-text-field
          v-model="options.search"
          append-icon="mdi-magnify"
          label="Pesquisar por nome do responsável"
          color="white"
          hide-details="auto"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [10, 30, 50, 100] }"
      class="no-line-row"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name | empty }}
      </template>

      <template v-slot:[`item.registration`]="{ item }">
        {{ item.kinship | empty }}
      </template>

      <template v-slot:[`item.phone`]="{ item }">
        <span>{{ item.phone | phone | empty }}</span>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <span>{{ item.email | empty }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon title="Liberar acesso do responsável" tooltip @click="releaseResponsibleAccess(item)">
          <v-icon small> mdi-cellphone-lock </v-icon>
        </v-btn>
        <v-btn icon title="Editar responsável" @click="edit(item)">
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        <v-btn icon color="error" title="Remover responsável" @click="remove(item)">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <modal-register-student-responsible ref="modalRegister" :student-id="studentId" @success="fetch" />
  </div>
</template>
